import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import {useDispatch} from 'react-redux'
import Pusher from 'pusher-js/types/src/core/pusher'
import {createPusher} from 'src/utils/pusher'
import {
  setBookingBadgeAction,
  setMessageBadgeAction,
  setPropertyBadgeAction,
} from 'src/store/actions/badgeAction'
import {useUserInfo, useIsLoggedIn, useIsAdmin} from 'src/hooks/user'
import {Channel} from 'pusher-js'
import {
  getBookingBadgesApi,
  getMessageBadgesApi,
  getPropertyBadgesApi,
} from 'src/services/api/badge'
import {usePrevious} from 'src/hooks/other'

interface PusherContextProps {
  pusher: Pusher | null
  refreshBadgeData: () => void
  badgeUpdated: boolean
}

export const PusherContext = createContext<PusherContextProps | undefined>(
  undefined,
)

PusherContext.displayName = 'PusherContext'

export const usePusherContext = () => {
  const context = useContext(PusherContext)
  if (context === undefined)
    throw new Error(`${PusherContext.displayName} must be used within provider`)

  return context
}

const BadgeUpdatedEvent = 'badge.updated'
const BookingsUpdatedEvent = 'bookings.updated'

export default function PusherProvider(props: {children: React.ReactChild}) {
  const [token, setToken] = useState<string | null>(null)
  const [pusherRef, setPusherRef] = useState<Pusher | null>(null)
  const [channelRef, setChannelRef] = useState<Channel | null>(null)
  const [updateCount, setUpdateCount] = useState<number>(0)

  const previousUpdateCount = usePrevious(updateCount)

  const userInfo = useUserInfo()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  const isAdmin = useIsAdmin()

  useEffect(() => {
    if (!isLoggedIn || !token) {
      return
    }

    const channelName = isAdmin
      ? `private-admin.${userInfo?.id}`
      : `private-badge.${userInfo?.id}`

    ;(async () => {
      const pusherInstance = await createPusher(token)
      if (!pusherInstance) return

      const channelInstance = pusherInstance.subscribe(channelName)

      function onMessage(data: any) {
        if (data.message) {
          dispatch(setMessageBadgeAction(data.message))
        }
        if (data.booking) {
          dispatch(setBookingBadgeAction(data.booking))
        }
        if (data.property) {
          dispatch(setPropertyBadgeAction(data.property))
        }
        setUpdateCount((prev) => prev + 1)
      }

      if (isAdmin) {
        channelInstance.bind(BookingsUpdatedEvent, onMessage)
      }

      if (!isAdmin) {
        channelInstance.bind(BadgeUpdatedEvent, onMessage)
      }

      setChannelRef(channelInstance)
      setPusherRef(pusherInstance)
    })()

    return () => {
      if (pusherRef && channelRef) {
        pusherRef.unsubscribe(channelName)
        channelRef.unbind(BadgeUpdatedEvent)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch, isLoggedIn])

  const refreshBadgeData = useCallback(() => {
    getMessageBadgesApi().then((data: any) =>
      dispatch(setMessageBadgeAction(data)),
    )
    getBookingBadgesApi().then((data: any) =>
      dispatch(setBookingBadgeAction(data)),
    )
    getPropertyBadgesApi().then((data: any) =>
      dispatch(setPropertyBadgeAction(data)),
    )
  }, [dispatch])

  useEffect(() => {
    if (!isLoggedIn || !token) {
      return
    }

    refreshBadgeData()
  }, [refreshBadgeData, dispatch, isLoggedIn, token])

  useEffect(() => {
    window.addEventListener('storage', () => {
      const token = localStorage.getItem('token')
      setToken(token)
    })

    setToken(localStorage.getItem('token'))
  }, [])

  return (
    <PusherContext.Provider
      value={{
        pusher: pusherRef,
        refreshBadgeData,
        badgeUpdated: previousUpdateCount !== updateCount,
      }}
    >
      {props.children}
    </PusherContext.Provider>
  )
}
