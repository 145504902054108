import {http} from 'src/services/http'

export async function getBookingBadgesApi() {
  const {data} = await http.get(`/bookings/badges`)

  return data
}

export async function getMessageBadgesApi() {
  const {data} = await http.get(`/messages/badges`)

  return data
}

export async function getPropertyBadgesApi() {
  const {data} = await http.get(`/properties/badges`)

  return data
}
