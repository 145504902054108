import {useCurrentLocale} from 'src/hooks/locale'
import {updateLanguageApi} from 'src/services/api/user'
import {LANGUAGE_EN, LANGUAGE_FR, LOCALE_EN} from 'src/constants/locale'
import {useUserInfo} from 'src/hooks/user'
import {useCallback, useEffect, useState} from 'react'
import * as Sentry from '@sentry/nextjs'
import {publicVerifyUserOnboardingTokenApi} from 'src/services/api/auth'
import {LOGIN_SUCCESS} from 'src/store/actionTypes'
import {useRouter} from 'next/router'
import {useAppContext} from 'src/context/AppProvider'
import {useDispatch} from 'react-redux'
import * as gtm from 'src/lib/gtm'
import * as gtag from 'src/lib/gtag'
import * as fbp from 'src/lib/fbpixel'
import Script from 'next/script'
import * as tiktok from 'src/lib/tiktok'
import {
  setDocumentHeight,
  setDocumentWidth,
} from 'src/store/actions/globalActions'
import throttle from 'lodash-es/throttle'
import {setDayJsLocale} from 'src/utils/date'
import {useBuildId} from 'src/hooks/other'

const isProduction = process.env.NODE_ENV === 'production'

export default function OnLoadContainer(props: {children: React.ReactNode}) {
  const {children} = props

  useBuildId() //will refetch app if we changed js version
  const [isFetchingOnboarding, setIsFetchingOnboarding] = useState(false)
  const [forceStop, setForceStop] = useState(false)
  const currentLocale = useCurrentLocale()
  const currentLang = currentLocale === LOCALE_EN ? LANGUAGE_EN : LANGUAGE_FR
  const userInfo = useUserInfo()
  const router = useRouter()
  const {setModal, cookieConsentSettings} = useAppContext()
  const dispatch = useDispatch()
  const ortOnboardingToken =
    (router.query.onboarding_token as string) || undefined

  const changeUserLang = async (lang: string) => {
    try {
      await updateLanguageApi(lang)
    } catch (e) {
      setForceStop(true) //make sure we dont make infinite loop
      Sentry.captureException(e)
      // eslint-disable-next-line no-console
      console.error(e)
      //dont do nothing, just redirect
    }
  }

  //next js issue with style module and next js
  //https://github.com/vercel/next.js/issues/47655
  const fixDynamicStyleSheets = () => {
    //make sure we load all css available
    const styles = Array.from(
      document.querySelectorAll("link[rel='preload'][as='style']"),
    ) as HTMLLinkElement[]
    styles.forEach((link: HTMLLinkElement) => {
      link.rel = 'stylesheet'
    })
  }

  useEffect(() => {
    setDayJsLocale(currentLocale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDayJsLocale(currentLocale)
  }, [currentLocale])

  useEffect(() => {
    fixDynamicStyleSheets()
  }, [])

  useEffect(() => {
    function handleResize() {
      dispatch(setDocumentWidth(window.innerWidth))
      dispatch(setDocumentHeight(window.innerHeight))
    }
    const func = throttle(handleResize, 500, {
      leading: true,
      trailing: true,
    })
    // Add event listener
    window.addEventListener('resize', func, {passive: true})
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', func)
  }, [dispatch])

  const handleRouteChange = useCallback(
    (url: any) => {
      //if (!cookieConsentSettings.consentAnalytics) return
      fixDynamicStyleSheets()

      if (isProduction) {
        gtm.pageview(url, userInfo)
        gtag.pageview(url)
        fbp.pageview()
      }
    },
    [userInfo],
  )

  useEffect(() => {
    if (forceStop) {
      return //if we force not updating
    }

    if (!userInfo) {
      return
    }

    if (userInfo.language === currentLang) {
      return
    }

    changeUserLang(currentLang)
  }, [forceStop, currentLang, userInfo])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, router.events])

  useEffect(() => {
    if (ortOnboardingToken && !userInfo && !isFetchingOnboarding) {
      setIsFetchingOnboarding(true)
      publicVerifyUserOnboardingTokenApi(ortOnboardingToken)
        .then((res) => {
          if (res.status === 200) {
            setModal('ort-onboarding')
            //todo on each login success check if onboarding token is there, if yes, open modal
            dispatch({
              payload: res.data,
              type: LOGIN_SUCCESS,
            })
          }
        })
        .finally(() => {
          setIsFetchingOnboarding(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ortOnboardingToken, userInfo])

  useEffect(() => {
    //if we are login and have token, popup
    if (userInfo && userInfo.onboarding_token) {
      setModal('ort-onboarding')
    }
  }, [setModal, userInfo])

  return (
    <>
      {children}

      <ScriptContent />
      {cookieConsentSettings.consentAnalytics && <ScriptContentNeedConsent />}
    </>
  )
}

function ScriptContent() {
  const {cookieConsentSettings} = useAppContext()

  useEffect(() => {
    if (cookieConsentSettings.showPopup) return //still not accepted
    //make sure it's really loaded
    gtag.updateConsent(cookieConsentSettings)
  }, [cookieConsentSettings])

  if (!isProduction) {
    return null
  }

  return (
    <>
      {gtm.MICROSOFT_CLARITY_ID && (
        <>
          <link rel="dns-prefetch" href="https://y.clarity.ms" />
          <Script
            id="clarity-base"
            async
            defer
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
           (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${gtm.MICROSOFT_CLARITY_ID}");
          `,
            }}
          ></Script>
        </>
      )}

      {gtag.GA_TRACKING_ID && (
        <>
          <Script
            async
            defer
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            strategy="afterInteractive"
          />
          <Script
            id="gtag-init"
            async
            defer
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          // Set default consent to 'denied' as a placeholder
          // Determine actual values based on your own requirements
          gtag('consent', 'default', {
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
          });

      gtag('js', new Date());
      gtag('config', '${gtag.GA_TRACKING_ID}', {
        page_path: window.location.pathname,
      });
    `,
            }}
          />
        </>
      )}

      {gtm.GTM_ID && (
        <>
          <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
          <Script
            id="gtag-base"
            async
            defer
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;defer=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtm.GTM_ID}');
    `,
            }}
          />
          <noscript>
            <iframe
              title="google tag manager"
              src={`https://www.googletagmanager.com/ns.html?id=${gtm.GTM_ID}`}
              height="0"
              width="0"
              className="g-fake-hide"
              style={{display: 'none', visibility: 'hidden'}}
            />
          </noscript>
        </>
      )}
    </>
  )
}

function ScriptContentNeedConsent() {
  if (!isProduction) {
    return null
  }

  return (
    <>
      {fbp.FB_PIXEL_ID && (
        <>
          <link rel="dns-prefetch" href="https://connect.facebook.net" />
          <Script
            id="fb-pixel"
            async
            defer
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${fbp.FB_PIXEL_ID});
    `,
            }}
          />
        </>
      )}
      {tiktok.TIKTOK_ID && (
        <>
          <link rel="dns-prefetch" href="https://analytics.tiktok.com" />
          <Script
            id="tiktok"
            async
            defer
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('${tiktok.TIKTOK_ID}');
        ttq.page();
      }(window, document, 'ttq');
    `,
            }}
          />
        </>
      )}

      {fbp.FB_PIXEL_ID && (
        <img
          alt="fb pixel img"
          height="1"
          width="1"
          className="g-fake-hide"
          style={{display: 'none'}}
          src={`https://www.facebook.com/tr?id=${fbp.FB_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      )}
    </>
  )
}
