import {useCallback} from 'react'
import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {useRouter} from 'next/router'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AppDialog from 'src/components/elements/Dialog'
import {useSmallSize} from 'src/hooks/screenSize'
import {useCurrentLocale} from 'src/hooks/locale'
import {convertPathnameWithLocale} from 'src/utils/locale'
import {useAppContext} from 'src/context/AppProvider'
import {DEFAULT_LOCALE, LOCALE_EN, LOCALE_FR} from 'src/constants/locale'
import {LocaleType} from 'src/types/utils'
import {HOME_PAGE} from 'src/constants/route'
import {useStyles} from './styles'
import {useRouterLocationPathname} from 'src/hooks/other'
import AppTypography from 'src/components/elements/typography/AppTypography'

export default function LanguagesDialog(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props

  const {currentPageBuilderPage} = useAppContext()
  const {t} = useTranslation('header')
  const router = useRouter()
  const {locale} = router
  const currentLocale = useCurrentLocale()
  const isSmallSize = useSmallSize()
  const completePathname = useRouterLocationPathname()

  const classes = useStyles()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleChangeLanguage = (localeWanted: LocaleType) => async () => {
    if (localeWanted === locale) {
      return
    }

    //dont save update lang on switch, we will do it when the next locale url is loading in <OnLoadContainer
    let finalPathname =
      (currentLocale !== DEFAULT_LOCALE ? `/${currentLocale}` : '') +
      (completePathname === HOME_PAGE ? '/' : completePathname)

    const {newAsPath} = convertPathnameWithLocale(
      finalPathname,
      locale as string,
      localeWanted,
      {
        currentPage: currentPageBuilderPage,
      },
    )
    //force reload so we get the freshest data
    window.location.href = newAsPath + window.location.search
  }

  return (
    <AppDialog
      className={classes.regionDialogWrapper}
      maxWidth="sm"
      open={open}
      title={t('language')}
      onClose={handleClose}
    >
      <div>
        <div
          className={classes.regionOptionsContainer}
          style={{flexWrap: isSmallSize ? 'wrap' : undefined}}
        >
          <div
            className={clsx(
              classes.regionItemContainer,
              isSmallSize && classes.regionOptionsContainerSM,
            )}
            onClick={handleChangeLanguage(LOCALE_EN)}
          >
            <AppTypography variant="body">
              <img
                src="/images/EN.svg"
                alt="en language"
                className={classes.flagIcon}
              />
              English
            </AppTypography>
            {currentLocale === LOCALE_EN && (
              <CheckCircleIcon className={classes.checkIcon} />
            )}
          </div>
          <div
            className={clsx(
              classes.regionItemContainer,
              isSmallSize && classes.regionOptionsContainerSM,
            )}
            onClick={handleChangeLanguage(LOCALE_FR)}
          >
            <AppTypography variant="body">
              <img
                src="/images/FR.svg"
                alt="fr language"
                className={classes.flagIcon}
              />
              Français
            </AppTypography>
            {currentLocale === LOCALE_FR && (
              <CheckCircleIcon className={classes.checkIcon} />
            )}
          </div>
        </div>
      </div>
    </AppDialog>
  )
}
