import {createSelector} from 'reselect'
import {RootState} from 'src/store'

export const bookingBadgeSelector = createSelector(
  (state: RootState) => state.badge.booking,
  (payload) => payload,
)

export const messageBadgeSelector = createSelector(
  (state: RootState) => state.badge.message,
  (payload) => payload,
)

export const propertyBadgeSelector = createSelector(
  (state: RootState) => state.badge.property,
  (payload) => payload,
)
