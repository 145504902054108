import {
  SET_BOOKING_BADGE,
  SET_PROPERTY_BADGE,
  SET_MESSAGE_BADGE,
} from 'src/store/actionTypes'

export const setBookingBadgeAction = (payload: any) => {
  return {
    type: SET_BOOKING_BADGE,
    payload,
  }
}

export const setPropertyBadgeAction = (payload: any) => {
  return {
    type: SET_PROPERTY_BADGE,
    payload,
  }
}

export const setMessageBadgeAction = (payload: any) => {
  return {
    type: SET_MESSAGE_BADGE,
    payload,
  }
}
