import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {ButtonProps} from '@material-ui/core/Button'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import ArrowLeftIcon from 'src/components/elements/icons/ArrowLeft'
import {useStyles} from './styles'
import Container, {ContainerPropsType} from 'src/components/helpers/Container'

export default function ActionButtonGroup(props: {
  containerProps?: Omit<ContainerPropsType, 'children'>
  primaryBtnProps?: ButtonProps
  secondaryBtnProps?: ButtonProps
}) {
  const {primaryBtnProps, secondaryBtnProps, containerProps} = props

  const {t} = useTranslation('common')
  const classes = useStyles()

  //fix disabled false being disabled...
  if (primaryBtnProps && !primaryBtnProps.disabled) {
    delete primaryBtnProps.disabled
  }
  if (secondaryBtnProps && !secondaryBtnProps.disabled) {
    delete secondaryBtnProps.disabled
  }

  return (
    <Container
      {...containerProps}
      className={clsx(containerProps?.className, classes.root)}
    >
      <AppRoundedButton
        children={t('back')}
        startIcon={<ArrowLeftIcon />}
        variant="outlined"
        {...secondaryBtnProps}
        className={clsx(classes.secondaryBtn, secondaryBtnProps?.className)}
      />
      <AppRoundedButton
        children={t('next')}
        color="primary"
        variant="contained"
        {...primaryBtnProps}
        className={clsx(classes.primaryBtn, primaryBtnProps?.className)}
      />
    </Container>
  )
}
