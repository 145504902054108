import {TypographyOptions} from '@material-ui/core/styles/createTypography'
import {CSSProperties} from '@material-ui/core/styles/withStyles'
//todo remove all of that at some point
export const outfitFontFamily = `'Outfit', Arial, sans-serif`
export const interFontFamily = 'Inter, Arial, sans-serif'

const size14Style: CSSProperties = {
  fontFamily: interFontFamily,
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'none',
  letterSpacing: '-0.016em',
}

const size12Style: CSSProperties = {
  fontFamily: interFontFamily,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  textTransform: 'none',
  letterSpacing: '-0.016em',
}

export const typography: TypographyOptions = {
  h1: {
    fontFamily: outfitFontFamily,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-0.028em',
  },
  h2: {
    fontFamily: outfitFontFamily,
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.028em',
  },
  h3: {
    fontFamily: interFontFamily,
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.028em',
  },
  h4: {
    fontFamily: outfitFontFamily,
  },
  h5: {
    fontFamily: interFontFamily,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
  },
  h6: {
    fontFamily: interFontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.028em',
  },
  body1: {
    // Body 16
    fontFamily: interFontFamily,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '-0.016em',
  },
  body2: {
    // Body 14
    ...size14Style,
    fontWeight: 400,
  },
  button: {
    // Additional / SubHeading 14
    ...size14Style,
    fontWeight: 500,
  },
  caption: {
    // Additional 12
    ...size12Style,
    fontWeight: 500,
  },
}
