import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import Dialog, {DialogProps} from '@material-ui/core/Dialog'
import {makeStyles} from '@material-ui/core/styles'
import AppTypography from 'src/components/elements/typography/AppTypography'
import CloseIcon from 'src/components/elements/icons/Close'
import AppDivider from 'src/components/elements/AppDivider'
import {Fragment} from 'react'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing}) => ({
    paper: {
      borderRadius: spacing(1.5),
      padding: spacing(4),
      width: '100%',
      [breakpoints.down('xs')]: {
        padding: spacing(3),
      },
    },
  }),
  {index: 1},
)

//todo change that bad boy for a custom dialog solution without material
export default function AppDialog(
  props: DialogProps & {
    title?: string
    childrenLeft?: React.ReactNode
  },
) {
  const {title, childrenLeft, classes: dialogClasses, ...otherProps} = props
  const classes = useStyles()

  return (
    <Dialog
      {...otherProps}
      classes={{
        paper: clsx(dialogClasses?.paper, classes.paper),
      }}
    >
      <Title title={title} childrenLeft={childrenLeft} {...props} />
      {props.children}
    </Dialog>
  )
}

function Title(
  props: DialogProps & {
    title?: string
    childrenLeft?: React.ReactNode
  },
) {
  const {title, childrenLeft, onClose} = props

  if (!title) {
    return null
  }

  const handleClose = (e: any) => {
    if (!onClose) return
    onClose(e, 'backdropClick')
  }

  return (
    <Fragment>
      <div className="flex flex-row justify-between items-center">
        {childrenLeft}
        <AppTypography variant="heading" neutralColor={900}>
          {title}
        </AppTypography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <AppDivider className="my-4" />
    </Fragment>
  )
}
