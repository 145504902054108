import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Visible from 'src/components/helpers/Visible'
import classes from './styles.module.scss'
import AppTypographyLink from 'src/components/elements/AppTypographyLink'
import {useAppContext} from 'src/context/AppProvider'
import EnhancedSwitch from 'src/components/forms/EnhancedSwitch'
import {LOCALE_EN} from 'src/constants/locale'
import {useCurrentLocale} from 'src/hooks/locale'
import {dayjs} from 'src/utils/date'
import clsx from 'clsx'

const isClient = typeof window !== 'undefined'

export default function CookiesConsentPopup() {
  const {t} = useTranslation(['common', 'footer'])
  const [step, setStep] = useState<1 | 2>(1)

  const currentLocale = useCurrentLocale()
  const {cookieConsentSettings, setCookieConsentSettings} = useAppContext()

  const handleSwitchChange = (e: any) => {
    setCookieConsentSettings((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }))
  }
  const acceptCookies = () => {
    setCookieConsentSettings((prev) => ({
      ...prev,
      lastUpdatedAt: dayjs().format(),
      showPopup: false, //hide
      //consent to all
      consentEssentials: true,
      consentMarketings: true,
      consentAnalytics: true,
      consentStatsCollection: true,
    }))
  }

  const acceptCookiesFromPref = () => {
    setCookieConsentSettings((prev) => ({
      ...prev,
      lastUpdatedAt: dayjs().format(),
      showPopup: false, //hide
    }))
  }

  if (!cookieConsentSettings.showPopup) {
    return null
  }
  if (!isClient) {
    return null //dont render on server
  }

  const policyLink =
    currentLocale === LOCALE_EN
      ? '/en/privacy-policy'
      : '/politique-de-confidentialite'

  const settings: {
    name:
      | 'consentEssentials'
      | 'consentMarketings'
      | 'consentAnalytics'
      | 'consentStatsCollection'
    disabled?: boolean
    title: string
    description: string
  }[] = [
    {
      name: 'consentEssentials',
      title: t('essentials_cookies_title'),
      description: t('essentials_cookies_description'),
      disabled: true,
    },
    {
      name: 'consentMarketings',
      title: t('marketing_cookies_title'),
      description: t('marketing_cookies_description'),
      disabled: false,
    },
    {
      name: 'consentStatsCollection',
      title: t('functional_cookies_title'),
      description: t('functional_cookies_description'),
      disabled: false,
    },
    {
      name: 'consentAnalytics',
      title: t('analytics_cookies_title'),
      description: t('analytics_cookies_description'),
      disabled: false,
    },
  ]

  return (
    <div className={classes.container}>
      <div className="mx-auto">
        <Visible when={step === 1}>
          <div className="w-full lg:flex flex-row justify-start items-center space-x-2">
            <div className="w-full lg:w-2/3">
              <AppTypography variant="body" className={classes.text}>
                {t('cookie_consent_description')}&nbsp;
                <span className="inline-block">
                  <AppTypographyLink
                    className={clsx(classes.text, 'inline-block w-auto')}
                    variant="body2"
                    href={policyLink}
                    underlineOnHover
                  >
                    {t('data_privacy_policy_protection')}
                  </AppTypographyLink>
                </span>
              </AppTypography>
            </div>
            <div className="w-full lg:w-1/3 mt-6 lg:mt-0 flex flex-row justify-center lg:justify-end items-center gap-4">
              <AppButton
                className="w-1/2 sm:w-32"
                variant="outlined"
                color="primary"
                onClick={() => setStep(2)}
                type="button"
              >
                {t('cookie_consent_show_settings')}
              </AppButton>

              <AppButton
                className="w-1/2 sm:w-32"
                variant="contained"
                color="primary"
                onClick={acceptCookies}
                type="button"
              >
                OK
              </AppButton>
            </div>
          </div>
        </Visible>

        <Visible when={step === 2}>
          <div className="w-full lg:flex flex-row justify-start items-center space-x-2">
            <div className="w-full lg:w-4/5">
              <AppTypography variant="heading" className="pb-1">
                {t('cookie_consent_show_settings')}
              </AppTypography>
              <div className="lg:flex flex-row justify-center items-stretch space-y-4 lg:space-y-0 lg:space-x-4">
                {settings.map((setting) => {
                  return (
                    <div
                      className="flex flex-col justify-between items-start"
                      key={setting.name}
                    >
                      <AppTypography
                        variant="action"
                        neutralColor={800}
                        component="label"
                        htmlFor={setting.name}
                      >
                        {setting.title}
                      </AppTypography>
                      <AppTypography
                        variant="body"
                        className={clsx(classes.text, 'py-1')}
                      >
                        {setting.description}
                      </AppTypography>
                      <EnhancedSwitch
                        disabled={setting.disabled}
                        name={setting.name}
                        onChange={handleSwitchChange}
                        checked={cookieConsentSettings[setting.name]}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full lg:w-1/5 mt-6 lg:mt-0 flex flex-row justify-center lg:justify-end items-center gap-4">
              <AppButton
                className="w-1/2 sm:w-32"
                variant="outlined"
                color="primary"
                onClick={() => setStep(1)}
                type="button"
              >
                {t('back')}
              </AppButton>

              <AppButton
                className="w-1/2 sm:w-32"
                variant="contained"
                color="primary"
                onClick={acceptCookiesFromPref}
                type="button"
              >
                OK
              </AppButton>
            </div>
          </div>
        </Visible>
      </div>
    </div>
  )
}
