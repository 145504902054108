import {combineReducers} from 'redux'
import userReducer from './userReducer'
import propertySearchReducer from './propertySearchReducer'
import accountSettingsReducer from './accountSettingsReducer'
import badgeReducer from './badgeReducer'
import globalReducer from './globalReducer'

const RootReducer = combineReducers({
  user: userReducer,
  propertySearch: propertySearchReducer,
  accountSettings: accountSettingsReducer,
  badge: badgeReducer,
  global: globalReducer,
})

export default RootReducer
