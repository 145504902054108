// import {dayjs} from 'src/utils/date'
import {Order} from 'src/types/search'
import {http} from 'src/services/http'
import {GuestSelectProps} from 'src/types/guestSelect'
import {CancelToken} from 'axios'
import {
  BookingDiscountInfo,
  BookingListProps,
  BookingModificationType,
  ProtectGroupExtraFeeType,
} from 'src/types/booking'
import {SelectDateProps} from 'src/types/utils'
import {Dayjs} from 'src/utils/date'

export type SearchData = {
  pending?: boolean
  accepted?: boolean
  preAccepted?: boolean
  canceled?: boolean
  rejected?: boolean
  toReview?: boolean
  passed?: boolean
  ongoing?: boolean
  expired?: boolean
  contested?: string | null
  protectGroupAccepted?: boolean
  search?: string | null
  order?: Order
  orderBy?: string
  page?: number
  limit?: number
  dateRange?: SelectDateProps | null
  dateSearchType?: string
  propertyId?: number
  user_id?: number
  tagId?: number | null
  color?: string
}

type BookingData = {
  start: string | Dayjs
  end: string | Dayjs
  options?: GuestSelectProps | null
  propertyId?: number
  locale?: string
  first_message?: string
  is_request_information?: boolean
  bookingId?: number
  arriving_time?: string
  departure_time?: string
  user_id?: number | null
  discount?: BookingDiscountInfo
  payment_mode?: 'stripe' | 'outside'
  extra_fees?: {protect_group?: ProtectGroupExtraFeeType}
}

export async function bookingApi({
  start,
  end,
  options,
  propertyId,
  locale,
  first_message,
  is_request_information,
  user_id,
  discount,
  payment_mode,
  extra_fees,
}: BookingData) {
  // const utcOffset = dayjs().utcOffset()
  const {data} = await http.post(`/property/${propertyId}/bookings`, {
    start,
    end,
    options,
    locale,
    user_id: user_id,
    // utcOffset: utcOffset * 60,
    first_message,
    is_request_information: is_request_information ?? false,
    discount,
    payment_mode,
    extra_fees,
  })

  return data
}

export async function updateBookingApi({
  start,
  end,
  options,
  discount,
  arriving_time,
  departure_time,
  bookingId,
}: BookingData) {
  const {data} = await http.put(`/booking/${bookingId}`, {
    start,
    end,
    options,
    discount,
    arriving_time,
    departure_time,
  })

  return data
}

export async function publicCheckBookingAvailableApi({
  start,
  end,
  options,
  propertyId,
  locale,
}: BookingData) {
  // const utcOffset = dayjs().utcOffset()
  const {data} = await http.post(
    `/public/property/${propertyId}/check_booking_available`,
    {
      start,
      end,
      options,
      locale,
      // utcOffset: utcOffset * 60,
    },
  )

  return data
}

export async function checkBookingAvailableAdvancedApi({
  propertyId,
  start,
  end,
  options,
  locale,
}: any) {
  const {data} = await http.post(
    `/property/${propertyId}/check_booking_available_advanced`,
    {
      start,
      end,
      options,
      locale,
    },
  )

  return data
}

export async function getMyBookingsApi(
  searchProps?: SearchData,
  cancelToken?: CancelToken,
) {
  const searchData: SearchData = {
    pending: searchProps?.pending,
    accepted: searchProps?.accepted,
    preAccepted: searchProps?.preAccepted,
    canceled: searchProps?.canceled,
    rejected: searchProps?.rejected,
    toReview: searchProps?.toReview,
    passed: searchProps?.passed,
    ongoing: searchProps?.ongoing,
    expired: searchProps?.expired,
    contested: searchProps?.contested,
    order: searchProps?.order,
    orderBy: searchProps?.orderBy,
    dateRange: searchProps?.dateRange,
    dateSearchType: searchProps?.dateSearchType,
    tagId: searchProps?.tagId,
    color:
      searchProps?.color === 'all' || !searchProps?.color
        ? undefined
        : searchProps?.color,
    page: searchProps?.page,
    limit: searchProps?.limit,
  }

  if (searchProps?.search) {
    searchData.search = searchProps?.search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/guest/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListProps
}

export async function getReservationsApi(
  {
    pending,
    accepted,
    preAccepted,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    propertyId,
    search,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    tagId,
    color,
    page = 1,
    limit = 50,
  }: SearchData,
  cancelToken?: CancelToken,
) {
  const searchData: SearchData = {
    pending,
    accepted,
    preAccepted,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    tagId,
    color: color === 'all' || !color ? '' : color,
    page,
    limit,
  }
  if (propertyId) {
    searchData.propertyId = propertyId
  }

  if (search) {
    searchData.search = search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/host/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListProps
}

export async function getAllBookingsApi(
  {
    pending,
    accepted,
    preAccepted,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    propertyId,
    user_id,
    search,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    protectGroupAccepted,
    tagId,
    color,
    page = 1,
    limit = 50,
  }: SearchData,
  cancelToken?: CancelToken,
) {
  const searchData: SearchData = {
    pending,
    preAccepted,
    accepted,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    protectGroupAccepted,
    tagId,
    color: color === 'all' || !color ? '' : color,
    page,
    limit,
  }
  if (propertyId) {
    searchData.propertyId = propertyId
  }

  if (user_id) {
    searchData.user_id = user_id
  }

  if (search) {
    searchData.search = search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListProps
}

export async function getMessageMasterApi(bookingUid: string) {
  const {data} = await http.get(`/booking/${bookingUid}/message_master`)

  return data
}

export async function cancelBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel`)

  return data
}

export async function acceptBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept`)

  return data
}

export async function rejectBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject`)

  return data
}

export async function publicGetBookingPriceApi(
  searchData: {
    propertyId: number
    start: string
    end: string
    options: GuestSelectProps
    discount?: BookingDiscountInfo
    extra_fees?: {
      protect_group?: ProtectGroupExtraFeeType
    }
  },
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'post',
    url: `/public/property/${searchData.propertyId}/booking_price`,
    data: {
      start: searchData.start,
      end: searchData.end,
      options: searchData.options,
      discount: searchData.discount,
      extra_fees: searchData.extra_fees,
    },
    cancelToken,
  })

  return data
}

export async function activateSecurityDepositRefundApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/enable_security_deposit_refund`,
  )

  return data
}

export async function deActivateSecurityDepositRefundApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/disable_security_deposit_refund`,
  )

  return data
}
export async function activateSecurityDepositApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/enable_security_deposit`)

  return data
}

export async function deActivateSecurityDepositApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/disable_security_deposit`,
  )

  return data
}

export async function setBookingCheckedApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/checked_by_host`)

  return data
}

export async function retryPaymentApi(bookingId: number) {
  const {data} = await http.post(`/booking/${bookingId}/retry_payment`)

  return data
}

export async function cancelByHostApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel_by_host`)

  return data
}

export async function cancelPreAcceptedBookingByHostApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/cancel_pre_accepted_by_host`,
  )

  return data
}

export async function cancelByAdminApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel_by_admin`)

  return data
}

export async function cancelByAdminAsGuestApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/cancel_by_admin_as_guest`,
  )

  return data
}

export async function getBookingByUidApi(bookingUid: string) {
  const {data} = await http.get(`/booking/uid/${bookingUid}`)

  return data
}

export async function getCancelPricesApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/get_cancel_prices`)

  return data
}

export async function sendCustomPricingApi(bookingId: number, amount: number) {
  const {data} = await http.post(`/booking/${bookingId}/send_custom_pricing`, {
    amount,
  })

  return data
}

export async function acceptCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept_custom_pricing`)

  return data
}

export async function declineCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject_custom_pricing`)

  return data
}

export async function cancelCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel_custom_pricing`)

  return data
}

export async function requestMoneyApi(bookingId: number, price: number) {
  const {data} = await http.post(`/booking/${bookingId}/request_money`, {
    amount: price,
  })

  return data
}

export async function adminUpdateRequestMoneyApi(
  bookingId: number,
  requestMoneyId: number,
  requestData: {amount?: number; admin_force_processed?: boolean},
) {
  const {data} = await http.put(
    `/booking/${bookingId}/request_money/${requestMoneyId}`,
    requestData,
  )

  return data
}

export async function acceptRequestMoneyApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept_request_money`)

  return data
}

export async function declineRequestMoneyApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject_request_money`)

  return data
}

export async function getBookingPricesWithCustomPricingApi(bookingId: number) {
  const {data} = await http.get(
    `/booking/${bookingId}/booking_prices_with_custom_pricing`,
  )

  return data
}

export async function bookNowApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/book_now`)

  return data
}

export async function preAcceptBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/pre_accept`)

  return data
}

export async function getBookingHistoryApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/history`)

  return data
}

export async function setBookingHistoryApi(
  bookingId: number,
  description: string | null,
) {
  const {data} = await http.post(`/booking/${bookingId}/history`, {
    description,
  })

  return data
}

export async function getBookingHistoryAttachmentsApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/history_attachments`)

  return data
}

export async function addBookingHistoryAttachmentApi(
  bookingId: number,
  file: FormData,
) {
  const {data} = await http.post(
    `/booking/${bookingId}/history_attachments`,
    file,
  )

  return data
}

export async function removeBookingHistoryAttachmentApi(
  bookingHistoryAttachmentId: number,
) {
  const {data} = await http.delete(
    `/booking_history_attachment/${bookingHistoryAttachmentId}`,
  )

  return data
}

export async function getBookingInvoice(bookingUid: string) {
  const {data} = await http.get(`/booking/${bookingUid}/invoice`, {
    responseType: 'blob', // important
  })

  return data
}

export async function updateBookingColorApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/color`)

  return data
}

export async function getChargeInfosApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/charge_infos`)

  return data
}

export async function getPaymentInfosApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/payment_methods`)

  return data
}

export async function updateBookingLeadApi(
  bookingId: number,
  lead: string | null,
) {
  const {data} = await http.put(`/booking/${bookingId}/lead`, {lead})

  return data
}

export async function updateBookingPriorityApi(
  bookingId: number,
  priority: string | null,
) {
  const {data} = await http.put(`/booking/${bookingId}/priority`, {
    priority,
  })

  return data
}

export async function adminGetBookingModifications(bookingId: number) {
  const {data} = await http.get(`/admin/booking/${bookingId}/modifications`)

  return data as BookingModificationType[]
}

export async function adminSendBookingNotification(
  bookingId: number,
  notification:
    | 'instructions'
    | 'arrival'
    | 'credit-card-issue'
    | 'departure'
    | 'payment-confirmation'
    | 'host-payment-confirmation'
    | 'security-deposit-refund'
    | 'security-deposit-taken',
) {
  const {data} = await http.post(`/admin/notifications/booking/${bookingId}`, {
    notification,
  })

  return data as {success: boolean}
}
