export const LOGIN = 'LOGIN'
export const LOGIN_BY_TOKEN = 'LOGIN_BY_TOKEN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_ME = 'GET_ME'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_FAIL = 'GET_ME_FAIL'

//Property search
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const SET_SELECTED_DATE_RANGE = 'SET_SELECTED_DATE_RANGE'
export const SET_SELECTED_GUESTS = 'SET_SELECTED_GUESTS'
export const SET_CURRENT_MAP_ZOOM = 'SET_CURRENT_MAP_ZOOM'
export const SET_SELECTED_AMENITIES = 'SET_SELECTED_AMENITIES'
export const SET_SEARCH_PAGE_NO = 'SET_SEARCH_PAGE_NO'
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS'
export const SET_MAP_CENTER_BOUNDS = 'SET_MAP_CENTER_BOUNDS'

export const SET_TAB_VIEW = 'SET_TAB_VIEW'
export const SET_ERROR_CODE = 'SET_ERROR_CODE'
export const SET_CLICKED_PROPERTY_UID = 'SET_CLICKED_PROPERRTY_UID'
export const SET_SCROLL_Y = 'SET_SCROLL_Y'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const SET_LAST_SEARCH_PARAMS = 'SET_LAST_SEARCH_PARAMS'

export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'

//Property search filters
export const SET_SORTBY_FILTER = 'SET_SORTBY_FILTER'
export const SET_MORE_FILTER = 'SET_MORE_FILTER'
export const SET_PRICE_RANGE_FILTER = 'SET_PRICE_RANGE_FILTER'

//Account Settings
export const REDIRECT_TO_SOCIAL_CONNECT = 'REDIRECT_TO_SOCIAL_CONNECT'
export const SET_SOCIAL_CONNECT_ERROR = 'SET_SOCIAL_CONNECT_ERROR'
export const FETCHING_API_DATA = 'FETCHING_API_DATA'

export const INIT_PROPERTY_STATE = 'INIT_PROPERTY_STATE'

export const INIT_PROPERTY_STATE_FROM_URL = 'INIT_PROPERTY_STATE_FROM_URL'

export const SWITCH_MENU = 'SWITCH_MENU'

export const SET_MESSAGE_BADGE = 'SET_MESSAGE_BADGE'

export const SET_BOOKING_BADGE = 'SET_BOOKING_BADGE'

export const SET_PROPERTY_BADGE = 'SET_PROPERTY_BADGE'

export const SET_DOCUMENT_WIDTH = 'SET_DOCUMENT_WIDTH'

export const SET_DOCUMENT_HEIGHT = 'SET_DOCUMENT_HEIGHT'
