import {CSSProperties, useMemo} from 'react'
import clsx from 'clsx'

export type ContainerPropsType = {
  children: React.ReactNode
  className?: string
  disableGutters?: boolean
  fixed?: boolean
  style?: CSSProperties | undefined
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

function Container(props: ContainerPropsType) {
  const {
    children,
    className,
    fixed = false,
    maxWidth = 'lg',
    style,
    disableGutters = false,
  } = props

  const maxWidthClasses = useMemo(() => {
    switch (maxWidth) {
      case 'xs':
        return 'max-w-[444px]'
      case 'sm':
        return 'max-w-screen-sm'
      case 'md':
        return 'max-w-screen-md'
      case 'lg':
        return 'max-w-screen-lg'
      case 'xl':
        return 'max-w-screen-xl'
      default:
        return ''
    }
  }, [maxWidth])

  const fixedClasses = useMemo(() => {
    if (!fixed) {
      return ''
    }

    return 'sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl'
  }, [fixed])

  return (
    <div
      style={style}
      className={clsx(
        'w-full mx-auto',
        maxWidthClasses,
        fixedClasses,
        disableGutters ? '' : 'px-4 md:px-6',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Container
